import { useCallback, useEffect, useRef, useState } from "react";
import { useAppState } from "AppProvider";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import type PortalItem from "@arcgis/core/portal/PortalItem";
import type PortalGroup from "@arcgis/core/portal/PortalGroup";
import Layer from "@arcgis/core/layers/Layer";

type PortalLayer = __esri.PortalLayer;
type SortField =
  | "title"
  | "uploaded"
  | "modified"
  | "username"
  | "created"
  | "type"
  | "owner"
  | "avg-rating"
  | "num-ratings"
  | "num-comments"
  | "num-views";

export function useGroupLayers() {
  const didCancel = useRef(false);
  const { config, portal } = useAppState();
  const [groupLayers, setGroupLayers] = useState<GroupLayer[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  // set flag to prevent setting state if component has unmounted
  useEffect(() => {
    didCancel.current = false;
    return function cleanup() {
      didCancel.current = true;
    };
  }, []);

  const fetchGroupLayers = useCallback(
    async ({
      groupQuery,
      groupItemsQuery,
      fetchSublayers,
      sortField = "modified",
      itemSortField = "title",
      sortOrder = "desc",
      itemSortOrder = "desc",
    }: {
      groupQuery: string;
      groupItemsQuery: string;
      fetchSublayers?: boolean;
      sortField?: SortField;
      itemSortField?: SortField;
      sortOrder?: "asc" | "desc";
      itemSortOrder?: "asc" | "desc";
    }) => {
      if (!portal || didCancel.current) {
        return;
      }
      setIsLoading(true);
      const groupResponse = await portal.queryGroups({
        query: groupQuery,
        sortField,
        sortOrder,
        num: 100,
      });

      const itemGroupResponse = await Promise.allSettled(
        groupResponse.results.map((r: PortalGroup) =>
          r.queryItems({
            query: groupItemsQuery,
            sortField: itemSortField,
            sortOrder: itemSortOrder,
            num: 100,
          })
        )
      );

      let hasErrors = false;
      const downloadRestrictionTag =
        config?.portal?.restrictDownloadTag?.toLowerCase() ?? null;
      const lyrs: GroupLayer[] = [];
      for (
        let groupIndex = 0;
        groupIndex < groupResponse.results.length;
        groupIndex++
      ) {
        const group = groupResponse.results[groupIndex] as PortalGroup;
        const response = itemGroupResponse[groupIndex];
        if (response.status === "rejected") {
          hasErrors = true;
          continue;
        }

        const items = response.value.results as PortalItem[];
        const promises: Array<Promise<Layer>> = [];

        const groupHasDownloadRestriction = downloadRestrictionTag
          ? group.tags.some((t) => t.toLowerCase() === downloadRestrictionTag)
          : false;

        for (const portalItem of items) {
          promises.push(
            Layer.fromPortalItem({
              portalItem,
            }).then((l) => l.load())
          );
        }

        const responses = await Promise.allSettled(promises);
        const layers: Layer[] = [];
        for (const response of responses) {
          if (response.status === "rejected") {
            hasErrors = true;
            continue;
          }
          if (fetchSublayers) {
            if (response.value instanceof GroupLayer) {
              for (const lyr of response.value.layers.toArray()) {
                await lyr.load();
              }
            }
          }

          // Default all layers to being turned off
          response.value.visible = false;
          layers.push(response.value);
        }

        const shouldMarkLayerAsRestricted =
          groupHasDownloadRestriction && downloadRestrictionTag;

        if (shouldMarkLayerAsRestricted) {
          for (const layer of layers) {
            const portalLayer = (layer as unknown) as PortalLayer;
            const tagDoesNotExist = portalLayer.portalItem.tags.every(
              (t: string) => t.toLowerCase() !== downloadRestrictionTag
            );

            if (tagDoesNotExist) {
              portalLayer.portalItem.tags.push(downloadRestrictionTag);
            }
          }
        }

        const groupLayer = new GroupLayer({
          id: group.id,
          title: group.title,
          layers,
        });

        lyrs.push(groupLayer);
        setGroupLayers([...lyrs]);
      }
      if (!didCancel.current) {
        setGroupLayers(lyrs);
        setIsLoading(false);
      }
      if (hasErrors) {
        // eslint-disable-next-line no-console
        console.error("Failed to load some or all map layers");
        if (!didCancel.current) {
          setError("Failed to load some or all map layers");
        }
      }
    },
    [config, portal]
  );

  return { fetchGroupLayers, groupLayers, setGroupLayers, isLoading, error };
}

export default useGroupLayers;
